:root {
  --subtle-border-color: #0c6ea733;
  --main-heading-color: #0c6ea7;
  --left-column-width: 30%;
  --right-column-width: 70%;
  --bullet-point-left-spacing: 18px;
}
h1,
h2,
h3,
h4,
p {
  margin: 0 0 4px;
}
h1 {
  color: var(--main-heading-color);
  margin: 0;
  line-height: 1;
}
h2 {
  color: #444;
  font-size: 1.1rem;
  font-weight: bold;
}
h3 {
  color: var(--main-heading-color);
  font-weight: bold;
  font-size: 0.9rem;
}
h4 {
  font-size: 0.9rem;
  font-weight: normal;
}
a {
  color: var(--main-heading-color);
  text-decoration: none;
}
p {
  font-size: 0.8rem;
  margin: 0 0 4px;
}
ul {
  font-size: 0.8rem;
  margin: 4px 0 0 4px;
  padding: 0;
}
ul li {
  line-height: 1.1;
  margin-bottom: 4px;
}
ul ul {
  margin-left: 17px;
}
small {
  font-size: 0.6rem;
}

.resume {
  width: 100%;
  max-width: 670px;
  margin: 40px auto;
  padding: 40px;
  box-shadow: 0px 10px 15px 2px rgba(204, 204, 204, 1);
}

@media print {
  .resume {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
  .resumeControls {
    display: none;
  }
}

.resumeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.resumeHeader > div:first-child {
  width: calc(var(--left-column-width) + var(--bullet-point-left-spacing));
}
.resumeHeader > div:last-child {
  width: calc(var(--right-column-width) - var(--bullet-point-left-spacing));
}
.resumeHeader .professionalSummary {
  font-weight: bold;
  opacity: 0.6;
  line-height: 1.3;
  padding: 5px 0;
}

.experienceEntry {
  border-top: 1px solid var(--subtle-border-color);
  margin-bottom: 8px;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
}
.experienceEntry h4 {
  display: flex;
  flex-direction: column;
}
.experienceEntry h4 > span {
  display: inline-block;
  padding: 0;
}
.experienceEntry > div:first-child {
  width: var(--left-column-width);
  padding: 2px 20px 0 0;
}
.experienceEntry > div:last-child {
  flex: var(--right-column-width);
}

.experienceEntry h4 .totalTime {
  display: flex;
  align-items: center;
  gap: 2px;
}

.experienceSummary {
  display: flex;
  flex-direction: column;
}
.experienceSummary small {
  margin-left: 4px;
  opacity: 0.8;
}

.labelledInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  font-size: 0.8rem;
}

.labelledInfo > span:first-child {
  font-size: 0.6rem;
  margin-right: 4px;
  opacity: 0.5;
}

.resumeDetailsBar {
  /* border-top: 1px solid var(--subtle-border-color); */
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.resumeDetailsBar > div:first-child {
  display: flex;
  flex-direction: row;
}

.resumeDetailsBar > div:last-child {
  text-align: right;
}

.contactInfo {
  font-size: 0.8rem;
  margin-left: 7px;
}
.contactInfo small {
  opacity: 0.8;
}

.skills {
  font-size: 0.8rem;
}

.atsLabel {
  display: none;
  /* overflow: hidden;
  display: inline-block;
  height: 15px;
  width: 15px;
  color: black;
  border: 1px solid red; */
}

/* For single column flag to benefit ATS systems */

.atsOptimized ul {
  margin-left: 16px;
}

.atsOptimized .resumeHeader {
  flex-direction: column;
  margin-bottom: 0;
}
.atsOptimized .resumeHeader > div:first-child,
.atsOptimized .resumeHeader > div:last-child {
  width: 100%;
}

.atsOptimized .experienceEntry,
.atsOptimized .resumeDetailsBar {
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.atsOptimized .experienceEntry h4 {
  flex-direction: column;
  /* gap: 10px; */
}
.atsOptimized .experienceEntry h4 > small:last-child {
  padding-top: 3px;
}
/* .atsOptimized .experienceEntry h4 .totalTime > .timeRange {
  display: none;
} */

.atsOptimized .experienceEntry > div:first-child {
  width: 100%;
}

.atsOptimized .atsLabel {
  display: inline-block;
  width: auto;
  margin-right: 5px;
  font-size: 1rem;
}

.atsOptimized .labelledInfo > span:first-child {
  width: 90px;
  font-size: 0.8rem;
}

.resumeControls {
  position: fixed;
  padding: 10px;
  border: 1px solid #ccc;
  top: 0;
  right: 0;
  background-color: #fff;
}

/* .experienceEntry > div:last-child {
  flex: 1;
} */

pre.resumePlainText {
  text-wrap: wrap;
  white-space: pre-wrap;
}
